@keyframes fade_in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.tooltip {
    position: relative;
    z-index: 99;

    &-box {
        position: absolute;
        display: none;
        animation-name: fade_in;
        animation-timing-function: ease-out;
        animation-duration: 225ms;
    }

    &.is-open &-box {
        display: block;
    }
}
