// Message box in the bottom page of waiting-room, functioning as input for
// AspirationUI, don't be confuse with snackbar :)
.messagebox {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 2;

    &-inner {
        padding: 20px;
    }

    &-message {
        height: 120px;
    }

    &-submit {
        display: block;
        margin-top: 10px;
    }
}
