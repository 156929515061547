.playroom-imagebox {
    background-color: mix($color-dark, transparent, 80);

    .popup-box {
        @include popup-with-timer-displayed;
        padding: 0;
        color: $color-light;
        background-color: transparent;
    }

    &-bar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        z-index: 2;
        transition: $transition--fast;
        transform: translateY(-100%);
        background-color: $color-dark;

        &.is-open {
            transform: translateY(0%);
        }

        &-inner {
            @include popup-with-timer-displayed;
            position: relative;
            width: 920px;
            margin-left: auto;
            margin-right: auto;

            .popup-close {
                top: 50%;
                right: 10px;
                z-index: 2;
                transform: translate(0, -50%);
            }

            .btn.is-hidden {
                display: none;
            }
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .popup-close {
            position: relative;
            transform: none;
            flex-shrink: 0;
            height: 2em;
            width: 2em;
            font-size: 1.2em;
        }
    }

    &-description {
        font-size: 0.86em;
    }

    &-actions {
        $button-gutter: 6px;
        display: inline-flex;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-right: (0 - $button-gutter);
        margin-left: (0 - $button-gutter);

        button {
            display: block;
            margin: 0 $button-gutter;

            svg {
                height: 1.4em;
                width: 1.4em;
                display: inline-block;
                vertical-align: middle;

                path {
                    fill: $color-light;
                }
            }

            &.is-hidden {
                display: none;
            }
        }
    }

    // &-fullscreen {
    //     margin-top: 20px;
    // }

    &-image {
        position: relative;
        margin-top: 2rem;

        &::after {
            @extend %pseudo-loading;
        }

        img {
            opacity: 1;
            max-width: 100%;
            height: auto;
            transition: $transition--fast;
        }

        &.is-loading {
            &::after {
                display: block;
            }

            img {
                opacity: 0;
            }
        }
    }

    // Disabled due to existence of fullscreen button & share button inside
    // the popup itself
    // &.is-fullscreen {
    //     .popup-box {
    //         max-width: none;
    //         width: 100%;
    //         margin: 0;
    //         padding: 0;
    //         // background-color: red;
    //     }

    //     .playroom-imagebox-content {
    //         position: absolute;
    //         top: 20px;
    //         left: 50%;
    //         transform: translateX(-50%);
    //         width: calc(100% - 40px);
    //         z-index: 2;
    //     }

    //     .playroom-imagebox-header {
    //         justify-content: flex-end;
    //     }

    //     .playroom-imagebox-title,
    //     .playroom-imagebox-description {
    //         display: none;
    //     }

    //     .playroom-imagebox-image {
    //         position: relative;
    //         margin: 0;
    //         height: 100vh;
    //         width: 100vw;
    //         pointer-events: none;

    //         img {
    //             position: absolute;
    //             top: 0;
    //             left: 0;
    //             display: block;
    //             width: 100%;
    //             height: 100%;
    //             margin: 0;
    //             object-fit: contain;
    //         }
    //     }
    // }

    &.is-ceo-laptop {
        .popup-box {
            display: flex;
            flex-direction: column-reverse;
        }

        .playroom-imagebox-header .popup-close {
            position: absolute;
            transform: translateY(-100%);
        }

        .playroom-imagebox-content,
        .playroom-imagebox-image {
            text-align: center;
        }

        .playroom-imagebox-title {
            width: 100%;
        }

        .playroom-imagebox-image img {
            height: 70px;
        }

        .playroom-imagebox-actions {
            display: none;
        }
    }
}
