#choose-room {
    min-height: 100vh;
    color: $color-light;
    background-size: cover;
    background-position: center;

    .choose-room-inner {
        padding-top: $navbar-height;
        padding-bottom: 40px;
    }

    .choose-room-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .choose-room-header-actions {
        $local-gutter: 6px;
        display: flex;
        margin-left: 0 - $local-gutter;
        margin-right: 0 - $local-gutter;

        .btn {
            display: block;
            min-width: 150px;
            margin: $local-gutter;
        }
    }

    .room {
        margin-top: 2rem;
        color: $color-dark;
        background-color: $color-light;

        &.is-empty {
            padding: 60px 20px;
            text-align: center;
        }

        .col {
            // background-color: red;

            &:nth-child(1) {
                width: 60px;
                max-width: 60px;
                text-align: center;
                font-weight: 700;
                // background-color: blue;
            }

            &:nth-child(3) {
                width: 240px;
                max-width: 240px;
            }
        }

        &-header {
            display: flex;
            padding: 20px 0;
            font-weight: 700;
            border-bottom: 1px solid mix($color-dark, transparent, 10);
        }

        &-item {
            padding: 20px 0;
            display: flex;
            align-items: center;
            word-break: break-all;

            &-btn {
                display: block;
                margin-right: 0;
                margin-left: auto;
                // margin-top: 10px;
            }

            &:nth-child(even) {
                background-color: mix($color-dark, transparent, 8);
            }
        }
    }

    // .choose-room-table {
    //     position: relative;
    //     height: 100vh;
    //     width: 100%;
    //     display: table;
    //     table-layout: fixed;
    //     z-index: 2;
    // }

    // .choose-room-cell {
    //     display: table-cell;
    //     vertical-align: middle;
    //     margin: auto;
    // }

    // .choose-room-inner {
    //     width: 750px;
    //     max-width: 100%;
    //     margin: auto;
    //     overflow: hidden;
    //     text-align: center;
    // }
}
