%escape-hidden {
    transform: translate(-50%, 100px);
    opacity: 0;
    pointer-events: none;
}

.escape {
    position: fixed;
    left: 50%;
    bottom: 20px;
    z-index: $zindex-escape;
    transform: translateX(-50%);
    opacity: 1;
    transition: $transition--fast;

    &.is-hidden {
        @extend %escape-hidden;
    }

    $avatar-size: 60px;

    &-button {
        width: 400px;
        padding: 10px $avatar-size;
        display: block;
        font-weight: 700;
        font-size: 1.4rem;
        text-align: center;
        box-shadow: $boxshadow-2;
        @include media-breakpoint-down(hd) {
            font-size: 1.2rem;
        }
    }

    &-members {
        position: absolute;
        top: auto;
        bottom: 100%;
        left: 0%;
        // display: flex;
        // flex-direction: column-reverse;
        transform: translate(-50%, #{$avatar-size / 2});

        img {
            height: $avatar-size;
            width: $avatar-size;
            display: block;
            margin-top: -50%;
            border-radius: 50%;
        }
    }
}

@include easyspa-loading {
    .escape {
        @extend %escape-hidden;
    }
}
