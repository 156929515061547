// ----------------------------------------------------------------------------
// This file contains CSS helper classes or extendable properties
// ----------------------------------------------------------------------------

%clearfix {
    content: "";
    clear: both;
    display: table;
}

%width-fit-content {
    display: table;
    width: auto;
}

%absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

%absolute-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

%flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes loading_spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

%pseudo-loading {
    @extend %absolute-center;
    content: "\f3f4";
    z-index: 2;
    font-family: "Font Awesome 5 Pro";
    font-size: 1.6em;
    animation-name: loading_spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    display: none;
}

%pseudo-overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

%pseudo-tooltip {
    content: attr(data-title);
    position: absolute;
    top: 50%;
    left: 80%;
    min-width: 60px;
    z-index: 4;
    padding: 2px 4px;
    opacity: 0;
    transition: $transition--fast;
    font-size: 0.74rem;
    line-height: 1.2;
    pointer-events: none;
    border-radius: 2px;
    background-color: $color-dark;
}

%playroom-app {
    @extend %pseudo-overlay;
    transition: $transition--primary;
    opacity: 0;
    transform: rotateX(30deg) scale(0.6);
    pointer-events: none;

    &.is-open {
        opacity: 1;
        transform: rotateX(0deg) scale(1);
        pointer-events: inherit;
    }
}
