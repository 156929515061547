.popup-fullscreen {
    z-index: $zindex-popup-fullscreen;
    background-color: mix($color-dark, transparent, 90);

    .popup-box {
        max-width: none;
        width: 100%;
        height: 100vh;
        margin: 0;
        padding: 0;
        display: table;
        table-layout: fixed;
        background-color: transparent;
    }

    .popup-close {
        z-index: 2;
        top: 20px;
        right: 20px;
        height: 2em;
        width: 2em;
        font-size: 1.2em;
        transform: translate(0, 0);
    }

    &-wrapper {
        display: table-cell;
        width: 100%;
        vertical-align: middle;

        // Why we need this? Internet Explorer. :)
        // because in IE table doesn't work well with pointer-event inheritance
        pointer-events: none;
    }

    img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100vh;
        margin: auto;
        object-fit: contain;
        display: none;

        &.is-active {
            display: block;
        }
    }

    &.is-open {
        &-wrapper {
            // Why we need this? Internet Explorer. :)
            // because in IE table doesn't work well with pointer-event inheritance
            pointer-events: inherit;
        }
    }
}
