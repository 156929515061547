// ----------------------------------------------------------------------------
// This file contains all global mixins
// ----------------------------------------------------------------------------
@mixin easyspa-loading {
    @at-root body[data-control="easy-spa-loader"].oc-loading {
        @content;
    }
}

// Space should be in pixel unit
@mixin popup-with-timer-displayed($space: $navbar-timer-popup-space) {
    max-width: calc(100% - #{($navbar-timer-width + $space) * 2});
}
