.pagination {
    gap: 10px;

    &.mi-pagina {

        .page-item {

            .page-link {
                min-height: 40px;
                min-width: 40px;
                justify-content: center;
                display: inline-flex;
                border-radius: 8px;
                margin: 0;
                line-height: 100%;
                align-items: center;
                background: rgba(255, 255, 255, .1);
                border: 0;
                color: #ffffff;

                &.next,
                &.prev {
                    font-size: 0;
                    position: relative;

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        border-style: solid;
                        border-width: 2px 2px 0 0;
                    }
                }

                &.next:after {
                    transform: rotate(45deg);
                }

                &.prev:after {
                    transform: rotate(-135deg);
                }
            }

            &.active {
                .page-link {
                    background: $color-accent-hex;
                    border: 0;
                }
            }
        }

    }
}