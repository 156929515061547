.game-player {
    $self: #{&};
    $player-gutter: 8px;
    position: relative;
    z-index: 1;
    text-align: center;

    &__scroller {
        overflow-x: auto;
        overflow-y: hidden;
        padding-left: 20px;
        padding-right: 20px;

        // Prevent layout shift
        height: 128px;

        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    &__list {
        display: inline-flex;
    }

    &__item {
        width: 116px;
        padding: $player-gutter;
        text-align: center;
    }

    &__avatar {
        height: 50px;
        width: 50px;
        margin: auto;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
    }

    &__name {
        margin-top: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.875rem;
    }

    &__delete {
        margin-top: 8px;
        padding: 2px 14px;
        font-size: 0.875rem;

        &::after {
            font-size: 1em;
        }
    }

    &__item.is-ready {
        #{$self}__name::before {
            content: "";
            display: inline-block;
            margin-right: 2px;
            height: 6px;
            width: 6px;
            transform: translateY(-2px);
            border-radius: 50%;
            background-color: $color-success;
        }
    }
}
