#waiting-room-old {
    padding-top: $navbar-height;
    color: $color-light;
    background-position: center;
    background-size: cover;
    // background-image: url("../../images/login.png");

    &::before {
        @extend %pseudo-overlay;
        opacity: 0.8;
        background-color: $color-dark;
    }

    .waiting-room {
        $right-width: 340px;
        $right-width-small: 280px;
        $left-width: 300px;
        $left-width-small: 270px;
        position: relative;
        height: calc(100vh - #{$navbar-height});
        display: flex;
        z-index: 1;

        &-left {
            position: relative;
            width: $left-width;
            flex-shrink: 0;

            @include media-breakpoint-down(hd-small) {
                width: $left-width-small;
            }
        }

        &-center {
            position: relative;
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .aspiration {
                flex-grow: 1;
            }
        }

        &-right {
            position: relative;
            width: $right-width;
            flex-shrink: 0;

            @include media-breakpoint-down(hd-small) {
                width: $right-width-small;
            }
        }

        &-info {
            padding-left: 20px;
            padding-right: 20px;

            @include media-breakpoint-down(hd) {
                font-size: 0.86rem;
            }
        }

        &-registered {
            flex-shrink: 1;
            padding-top: 40px;
            padding-bottom: 30px;
            text-align: center;

            @include media-breakpoint-down(lg) {
                font-size: 0.76rem;
            }

            @include media-breakpoint-down(hd) {
                font-size: 0.86rem;
            }

            &-title::after {
                content: "";
                display: block;
                height: 2px;
                width: 21em;
                max-width: 100%;
                margin: auto;
                margin-top: 0.7em;
                background-color: mix($color-light, transparent, 30);
            }

            &-users {
                @extend %flex-center;
                flex-wrap: wrap;
                max-width: calc(100vw - #{$left-width + $right-width});
                height: 60px;
                opacity: 0.8;
                margin-top: 10px;
                overflow: hidden;
                overflow: hidden;

                @include media-breakpoint-down(hd-small) {
                    $others: $left-width-small + $right-width-small;
                    max-width: calc(100vw - #{$others});
                }

                span {
                    display: block;
                }

                span::after {
                    content: "|";
                    margin: 0 0.5em;
                }

                span.is-ready::before {
                    content: "";
                    display: inline-block;
                    height: 6px;
                    width: 6px;
                    margin-right: 4px;
                    border-radius: 50%;
                    background-color: $color-success;
                }

                span:nth-last-child(1)::after {
                    content: none;
                }
            }
        }

        &-starting {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            $local-color: mix($color-primary, $color-dark, 60);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: $zindex-navbar + 1;
            pointer-events: none;
            opacity: 0;
            transition: $transition--fast;
            background-color: rgba($color-accent, .6);

            &.is-active {
                pointer-events: all;
                opacity: 1;
            }

            &-counter {
                position: relative;
                height: 160px;
                width: 160px;
                margin: auto;
                margin-top: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 4.8em;
                font-weight: 700;
                border: 6px solid $color-light;
                border-radius: 50%;

                &::after {
                    @extend %pseudo-loading;
                    font-size: 0.8em;
                }

                &.is-loading span {
                    display: none;
                }

                &.is-loading::after {
                    display: block;
                }
            }
        }
    }
}