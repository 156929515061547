.flatpickr-confirm {
    height: 40px;
    max-height: 0px;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.06);
}

.flatpickr-confirm svg path {
    fill: inherit;
}

.flatpickr-confirm.darkTheme {
    color: white;
    fill: white;
}

.flatpickr-confirm.visible {
    max-height: 40px;
    visibility: visible;
}
