.source-debrief {
    &-table {
        width: 100%;
        display: table;
        table-layout: fixed;
    }

    &-cell {
        display: table-cell;
        vertical-align: middle;
        margin: auto;
    }

    &-inner {
        margin: auto;
        padding-bottom: 40px;
    }

    &-content {
        position: relative;
        padding: 40px 60px;
        padding-top: 60px;
        color: $color-dark;
        background-color: $color-light;

        &::before {
            content: attr(data-title);
            position: absolute;
            top: 30px;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 0.86rem;
            letter-spacing: 0.2em;
            color: $color-accent-hex;
        }
    }
}