.leaderBoard {
    $this : #{&};

    &__inner {
        position: relative;
        display: flex;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    &__container {
        max-width: 560px;
        margin: 0 auto;
        width: 90%;
        max-height: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: 100%;

        &>h5 {
            font-size: 3em;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        flex: 1;
        padding: 20px 60px;
        overflow: auto;
    }

    &__item {
        position: relative;

        &--inner {
            padding: 0 0 0 20px;
        }

        &.is-top-3 {
            #{$this}__detail {
                background: $color-accent-hex;
            }

            #{$this}__number {
                color: #ffffff;
                box-shadow: none;
            }

            &:nth-child(1) {
                #{$this}__detail {
                    box-shadow: 0 0 0 2px #ECB21E;
                }

                #{$this}__number {
                    background: #ecb21e;
                }
            }

            &:nth-child(2) {
                #{$this}__detail {
                    box-shadow: 0 0 0 2px #D8D0BE;
                }

                #{$this}__number {
                    background: #D8D0BE;
                }
            }

            &:nth-child(3) {
                #{$this}__detail {
                    box-shadow: 0 0 0 2px #9C3721;
                }

                #{$this}__number {
                    background: #9C3721;
                }
            }
        }

        &.is-your-team {
            &:after {
                content: '';
                position: absolute;
                left: 100%;
                width: 40px;
                height: 40px;
                top: 50%;
                transform: translateY(-50%);
                background-size: 25px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url(../../images/team.png);
                opacity: .45;
            }

            #{$this}__detail {
                background: rgba(255, 255, 255, .1);
            }

            #{$this}__number {
                background: rgba(255, 255, 255, .1);
            }
        }
    }

    &__number {
        position: absolute;
        top: 50%;
        left: 0;
        width: 40px;
        height: 40px;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        z-index: 2;
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(10px);
        box-shadow: inset 0 0 0 2px rgba(255, 255, 255, .4);
        color: rgba(255, 255, 255, .4);
    }

    &__detail {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 15px 15px 15px 40px;
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(10px);

        .dash {
            margin-left: auto;
            width: 20px;
            height: 2px;
            background: #ffffff;
        }
    }
}