.snackbar {
    position: fixed;
    width: 100vw;
    z-index: $zindex-snackbar;
    bottom: 0;
    left: 0;
    transition: $transition--fast;
    transform: translateY(110%);
    word-break: break-all;

    &.is-active {
        transform: translateY(0%);
    }

    &-inner {
        padding: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        border-radius: 10px;
        background-color: $color-light;
    }

    &-body {
        flex-grow: 1;
        flex-shrink: 1;
        margin-right: 10px;
    }

    &-close {
        flex-shrink: 0;
        flex-grow: 0;
        color: $color-light;
    }
}
