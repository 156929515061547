// ----------------------------------------------------------------------------
// This file contains all the styles for Typography base
// ----------------------------------------------------------------------------

body {
    font-family: $font-family-body;
    line-height: 1.6;
    color: $color-dark;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.4;
    font-family: $font-family-title;
    font-weight: 700;
    margin-top: 0.4em;
    margin-bottom: 0.4em;
}

h1 {
    font-size: 3.6em;
}

h2 {
    font-size: 2.6em;
}

h3 {
    font-size: 1.8em;
}

h4 {
    font-size: 1.2em;
}

h5 {
    font-size: 1em;
}

h6 {
    font-size: 0.86em;
}

p {
    font-weight: normal;
}

// small {
// 	font-size: ;
// }

.typography-extended {
    letter-spacing: 0.14em;
}
