.toolbox-singlebrief {
    text-align: center;

    .popup-box {
        width: 650px;
        @include popup-with-timer-displayed;
    }

    .singlebrief {
        &-title::after {
            content: "";
            display: block;
            width: 8em;
            height: 2px;
            margin: 0.8em auto 1.2em auto;
            background-color: $color-accent-hex;
        }

        &-content {
            line-height: 1.4;
        }

        &-submit {
            margin-top: 0.6em;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}