#login {
    position: relative;
    background-position: center;
    background-size: cover;
    // background-image: url("../../images/login.png");

    &::before {
        @extend %pseudo-overlay;
        opacity: 0.5;
        background-color: $color-dark;
    }

    .login {
        height: 100vh;
        display: table;
        padding-top: $navbar-height;
        color: $color-light;

        &-wrapper {
            display: table-cell;
            vertical-align: middle;
        }

        &-suptitle {
            font-weight: bold;
            text-transform: uppercase;
        }

        &-title {
            margin-top: 0;
            margin-bottom: 0.2em;
            @include media-breakpoint-up(lg) {
                margin-left: -0.8em;
            }
        }

        &-description {
            @include media-breakpoint-up(lg) {
                width: 70%;
            }
        }

        &-form {
            margin-top: 2rem;
            @include media-breakpoint-up(lg) {
                width: 70%;
            }

            &-title {
                text-transform: uppercase;
            }

            input {
                color: $color-dark;
            }

            &-submit {
                display: block;
                min-width: 140px;
                margin-left: auto;
                margin-right: 0;
                margin-top: 8px;
            }
        }
    }
}
