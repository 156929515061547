// NOTE: IMPORTANT
// Every size assignment inside .document must use em, to make eveything
// inside of it scalable
.document {
    position: relative;
    // height: 740px;
    // width: 1200px;
    // height: 38.6em;
    padding-top: 38.6 / 62.5 * 100%;
    width: 62.5em;
    max-width: 100%;
    margin: auto;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    // background-color: red;
    background-image: url("../../images/document.png");

    &-left {
        // Additional space from the edge of image
        // $space: 60px;
        $space: 3.1em;

        // Proximity of center folds width from image
        // (see 4 vertical line that separate document)
        // NOTE: centerfolds is not perfectly in the middle of the image
        // $center-folds: 160px;
        $center-folds: 8.4em;

        position: absolute;
        width: 34%;
        top: $space;
        left: 50%;
        overflow: auto;
        transform: translateX(-100%) translateX(#{0 - ($center-folds / 2)});

        .document-left-share.is-shared {
            display: none;
        }
    }

    &-right {
        position: absolute;
        width: 40%;
        height: 94%;
        top: 1.4em;
        left: 50%;

        // Proximity range between center of image to the paper
        transform: translateX(0.8em);

        background-color: $color-light;
        // background-color: yellow;

        &::after {
            @extend %pseudo-loading;
            color: $color-dark;
        }

        &.is-loading {
            &::after {
                display: block;
            }

            .document-right-inner {
                opacity: 0;
            }
        }

        &.landing {
            opacity: 1;
            pointer-events: inherit;
            transition: $transition--fast;
            transform-origin: 50% 100%;
            transform: rotate(0deg) scale(1);

            .document-right-inner {
                padding: 2em;
            }
        }

        &.single {
            opacity: 0;
            pointer-events: none;
            transition: $transition--fast;
            transform-origin: 50% 100%;
            transform: rotate(25deg) scale(0.8);

            img {
                height: auto;
                max-width: 100%;
                display: none;

                &.is-open {
                    display: block;
                }
            }
        }

        &-close {
            @extend %absolute-center;
            height: 2em;
            width: 2em;
            top: 0%;
            left: 100%;
            padding: 0;
            border-radius: 50%;

            .far {
                @extend %absolute-center;
                margin-right: 0 !important;
                margin-left: 0 !important;
            }
        }

        &-inner {
            max-height: 100%;
            overflow: auto;
            transition: $transition--fast;
        }
    }

    &-action-wrapper {
        opacity: 0;
        pointer-events: none;
        transition: $transition--fast;
    }

    &-action {
        width: 100%;
        display: flex;
        text-align: left;
        text-align: start;
        align-items: center;
        font-weight: 700;
        font-size: 0.9em;
        background-color: transparent;

        svg {
            height: 3.2em;
            width: 3.2em;
            margin-right: 1.4em;

            path {
                fill: rgba($color-accent-hex, 1);
            }
        }

        &:hover,
        &:focus {
            background-color: mix($color-light, transparent, 6);
        }
    }

    &-company {
        position: relative;
        width: 100%;
        // display: flex;
        // flex-wrap: wrap;
        // align-items: center;

        &-logo {
            height: auto;
            width: auto;
            max-width: 80%;
            max-height: 60px;
            margin-right: 0.8em;
            vertical-align: middle;
        }

        &-name {
            display: inline-block;
            vertical-align: middle;
        }
    }

    &-list {
        &-item {
            $gutter-vertical: 3em;
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            // justify-content: center;
            margin-top: $gutter-vertical;
            padding-right: 0.5em;
            transition: $transition--fast;
            text-decoration: none;
            color: inherit;

            &:hover,
            &:focus {
                background-color: mix($color-dark, transparent, 10);
            }

            &::before {
                content: "";
                position: absolute;
                top: (0 - ($gutter-vertical / 2));
                left: 0;
                display: block;
                width: 100%;
                height: 1px;
                margin-bottom: 2em;
                pointer-events: none;
                background-color: mix($color-dark, transparent, 20);
            }

            &:nth-child(1)::before {
                opacity: 0;
            }

            img {
                height: 3.8em;
                width: 3.8em;
                object-fit: cover;
                margin-right: 0.7em;
                border: 1px solid $color-dark;
            }

            &-title {
                flex-grow: 1;
                font-size: 0.86em;
            }

            &-actions {
                $local-gutter: 0.4em;
                display: flex;
                justify-content: center;
                margin: (0 - $local-gutter);

                button {
                    display: block;
                    padding: $local-gutter;
                    font-size: 0.6em;
                    color: $color-accent-hex;
                    background-color: transparent;

                    .icon {
                        position: relative;
                        height: 3em;
                        width: 3em;
                        transition: $transition--fast;
                        border-radius: 50%;
                        background-color: $color-accent-hex;

                        svg {
                            @extend %absolute-center;
                            height: 80%;
                            width: 80%;

                            path {
                                fill: $color-light;
                            }
                        }
                    }

                    span {
                        display: block;
                        margin-top: 0.2em;
                    }

                    &:hover,
                    &:focus {
                        .icon {
                            background-color: color-mix(in srgb, #{$color-accent-hex}, #000 20%);
                        }
                    }
                }
            }

            &.is-shared {
                .document-action-share {
                    display: none;
                }
            }
        }
    }

    &.is-viewing {
        .document-action-wrapper {
            opacity: 1;
            pointer-events: all;
        }

        .document-right.landing {
            opacity: 0;
            pointer-events: none;
            transform: rotate(-25deg) scale(0.8);
        }

        .document-right.single {
            opacity: 1;
            pointer-events: inherit;
            transform: rotate(0deg) scale(1);
        }
    }
}

.document-popup {
    .popup-close {
        width: auto;
        top: 10px;
        padding: 0.2em 0.8em;
        border-radius: 0.2em;
    }

    .popup-box {
        display: table;
        table-layout: fixed;
        // width: fit-content;
        padding-right: 0;
        padding-left: 0;
        padding-top: 20px;
        background-color: transparent;
        @include popup-with-timer-displayed;
    }
}

.document-success-popup {
    z-index: $zindex-document-success-popup;
    text-align: center;

    .popup-box {
        @include popup-with-timer-displayed;
    }

    &-title {
        &::after {
            content: "";
            display: block;
            width: 8em;
            height: 2px;
            margin: 0.8em auto 1.2em auto;
            background-color: $color-accent-hex;
        }
    }

    &-proceed {
        width: 9em;
        font-size: 1.1em;
    }
}