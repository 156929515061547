#landing-game {
    position: relative;
    min-height: 100vh;
    background-position: center;
    background-size: cover;

    &::before {
        @extend %pseudo-overlay;
        background-color: rgba($color-dark, 0.8);
    }

    .row {
        justify-content: center;
    }
}

.p-landing {
    text-align: center;
    color: $color-light;

    &__table {
        min-height: 100vh;
        display: table;
        table-layout: fixed;
    }

    &__cell {
        display: table-cell;
        vertical-align: middle;
    }

    &__suptitle {
        @extend .typography-extended;
        font-weight: bold;
        text-transform: uppercase;
    }

    &__title {
        margin-top: 0;
        margin-bottom: 0.2em;
    }

    &__content {
        @include media-breakpoint-up(lg) {
            width: 70%;
            margin: auto;
        }
    }

    &__logo {
        display: inline-block;
        height: 1.5rem;
        margin-left: 0.5rem;
        vertical-align: middle;
        transform: translateY(-7%);
    }

    &__actions {
        $gutter: 8px;
        display: flex;
        margin: (0 - $gutter);
        margin-top: (40px - $gutter);
        justify-content: center;

        .btn {
            display: block;
            margin: $gutter;
            @include media-breakpoint-up(md) {
                padding-right: 32px;
                padding-left: 32px;
            }
        }

        .btn-secondary {
            color: $color-light;
            border-color: $color-light;

            &:hover,
            &:focus {
                background-color: rgba($color-light, 0.1);
            }
        }
    }
}
