.laptop {
    $button-space: 90px;
    $space-top: 20px;
    $laptop-width: 1400px;

    // We need this because overflow: hidden; in the &-wrapper, this is extra
    // space to prevent close button get cut by &-wrapper
    $close-button-bump: 10px;

    background-color: mix($color-dark, transparent, 80);

    .popup-box {
        @include popup-with-timer-displayed(0px);
        position: relative;
        width: 90vw;
        margin-top: 10px;
        margin-bottom: 0;
        padding: 0;
        text-align: center;
        background-color: transparent;
    }

    .popup-close {
        // NOTE: you should use percentage for positioning top, right, bottom,
        // left. Why? because this element is inside .laptop-screen, which
        // heavily using percentage in order to maintain aspect ratio
        top: -5%;
        right: -5%;

        transform: translate(0, 0);
        z-index: 2;
    }

    &-wrapper {
        // Enable this if IE won't work (alternative of right .objectruler)
        // To prevent unnecessary scrolling X in IE because of .objectruler
        // left 50%, watch out popup-close above the laptop
        overflow: hidden;
        padding-top: $close-button-bump;

        transition: $transition--primary;
        // transform: rotateX(60deg) scale(0.6);
        transform: scale(0.6);
    }

    &.is-open .laptop-wrapper {
        // transform: rotateX(0deg) scale(1);
        transform: scale(1);
    }

    // &.is-viewing-document{
    //     opacity: 0;
    // }

    &.is-viewing-document .laptop-wrapper {
        opacity: 0;
        transform: rotateX(0deg) scale(0.7);
    }

    &-image {
        width: $laptop-width;
        max-width: 100%;
    }

    // ELement that mimic the whole laptop-image size, so we could have the
    // base size of the screen
    &-objectruler {
        position: absolute;
        height: 0px;
        width: $laptop-width;
        max-width: 100%;
        right: 50%;
        top: 4%;
        margin-top: $close-button-bump;
        transform: translateX(50%);
        // padding-bottom: 50%;

        // To check the sizing, use this when you confuse :)
        // background-color: mix(yellow, transparent, 10);
    }

    &-screen {
        position: absolute;
        width: 78%;
        padding-bottom: 51%;
        // top: 2%;
        left: 50%;
        transform: translateX(-50%);

        // To check the sizing, use this when you confuse :)
        // background-color: mix(red, transparent, 20);
    }

    &-inner {
        @extend %pseudo-overlay;
        overflow: hidden;
        border-radius: 10px;

        // Normalize alignment
        text-align: start;

        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        // To check the sizing, use this when you confuse :)
        // background-color: mix(white, transparent, 20);
    }

    .escape {
        position: relative;
        transform: none;
        left: 0;
        margin-top: 20px;

        &-button {
            margin: auto;
        }
    }

    &-mainscreen {
        display: flex;
        flex-direction: column;
    }

    &-app {
        $app-size: 50px;
        $app-size-small: 50px;
        $app-padding: 20px;
        padding: $app-padding;
        width: ($app-size + ($app-padding * 2));
        background-color: transparent;
        @include media-breakpoint-down(hd) {
            width: ($app-size-small + ($app-padding * 2));
        }

        &:hover,
        &:focus {
            background-color: mix($color-dark, transparent, 10);
        }

        &-logo {
            height: $app-size;
            width: $app-size;
            display: block;
            object-fit: contain;
            @include media-breakpoint-down(hd) {
                height: $app-size-small;
                width: $app-size-small;
            }
        }

        &-name {
            width: $app-size;
            line-height: 1.2;
            font-size: 0.86em;
            margin-top: 0.4em;
            @include media-breakpoint-down(hd) {
                width: $app-size-small;
            }
            @include media-breakpoint-down(hd-small) {
                font-size: 0.7em;
            }
        }
    }

    &-document {
        .popup {
            position: absolute;
        }
    }
}

#popup-hr-analytic,
#popup-document-analytic {
    background-color: transparent;

    .popup-box {
        transition: $transition--fast;
        transform: scale(0.8);
    }

    &.is-open .popup-box {
        transform: scale(1);
    }
}
