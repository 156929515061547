.toolbox {
    $self: #{&};
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: $zindex-toolbox;

    &-main {
        display: flex;

        #{$self}-button {
            margin-right: 0.6em;
        }
    }

    &-button {
        $button-size: 70px;
        position: relative;
        height: $button-size;
        width: $button-size;
        display: block;
        margin-top: 10px;
        padding: 0;
        border-radius: 50%;
        box-shadow: $boxshadow-1;

        &::before {
            @extend %pseudo-tooltip;
        }

        // &:focus::before
        &:hover::before {
            opacity: 1;
        }

        svg {
            @extend %absolute-center;
            height: 75%;
            width: 75%;

            path {
                fill: $color-light;
            }
        }

        .badge {
            @extend %absolute-center;

            // 10% of $button-size
            $positioning: $button-size * 10 / 100;

            top: $positioning;
            left: calc(100% - #{$positioning});
            height: 1.6em;
            width: 1.6em;
            transition: $transition--fast;
            border-radius: 50%;
            color: $color-accent-hex;
            background-color: $color-light;

            span {
                @extend %absolute-center;
                font-weight: 700;
            }
        }
    }

    &-collapse {
        position: absolute;
        bottom: 100%;
        z-index: 1;
        transform: translateY(70px) scale(0.5);
        transition: $transition--fast;
        opacity: 0;
        pointer-events: none;
    }

    &-toggle {
        position: relative;
        z-index: 2;
    }

    &.is-open {
        #{$self}-collapse {
            transform: translateY(0px) scale(1);
            opacity: 1;
            pointer-events: all;
        }

        #{$self}-faq {
            transform: translateX(-30px) scale(0.5);
            opacity: 0;
            pointer-events: none;
        }

        #{$self}-toggle {
            background-color: $color-light;

            &:hover,
            &:focus {
                background-color: mix($color-dark, $color-light, 15);
            }

            svg path {
                fill: rgba($color-accent-hex, 1);
            }

            .badge {
                transform: translate(-50%, -50%) scale(0);
                opacity: 0;
                color: $color-light;
                background-color: $color-accent-hex;
            }
        }
    }
}

.toolbox-popup {
    $close-size: 50px;
    $thumb-size: 70px;
    $thumb-gutter: 14px;
    $thumb-scroll: 14px;
    $thumb-arrow-space: 12px;
    $thumb-root-size: (
        $thumb-size + $thumb-scroll + $thumb-arrow-space
    );
background-color: mix($color-dark, transparent, 90);

.popup-box {
    @include popup-with-timer-displayed;
    padding: 0;
    background-color: transparent;
    // background-color: mix($color-light, transparent, 10);
}

.popup-close {
    height: $close-size;
    width: $close-size;
    top: 50%;
    transform: translateY(-50%);

    .far {
        font-size: 1.4em;
    }
}

&-header {
    position: relative;
    // background-color: blue;
}

&-title {
    margin-left: $close-size;
    margin-right: $close-size;
    text-align: center;
    font-weight: normal;
    color: $color-light;
    // background-color: red;
}

&-control {
    &-title {
        padding-right: $close-size;
        padding-left: $close-size;
        font-size: 1.2em;
        flex-grow: 1;
        text-align: center;
        color: $color-light;
        white-space: nowrap;
        overflow: hidden;

        //  --------------------------------------------------
        // Text elipsis in the second line (only for -webkit-)
        @media screen and (-webkit-min-device-pixel-ratio: 0) {
            white-space: inherit;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        //  --------------------------------------------------
    }

    &-actions {
        display: flex;
        justify-content: center;
        margin: 0 -4px;
        margin-top: 0.8em;

        button {
            margin: 4px;
            font-size: 0.8em;
        }
    }

    &-remove,
    &-fullscreen {
        &:disabled {
            pointer-events: none;
            opacity: 0 !important;
            background-color: transparent;
        }
    }
}

&-documents {
    position: relative;
    height: 75vh;
    margin-top: 20px;
    display: flex;

    .swiper-slide {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}

&-slider {
    // Aspect ratio 16 : 21.1
    // width: 32em;
    // height: 42.2em;

    // If you don't use aspect ratio
    flex-grow: 1;
    margin-left: ($thumb-root-size + $thumb-gutter);

    &-arrows {
        position: absolute;
        width: calc(100% - #{($thumb-root-size + $thumb-gutter) * 2});
        top: 50%;
        left: 0;
        z-index: 3;
        margin-right: $thumb-root-size + $thumb-gutter;
        margin-left: $thumb-root-size + $thumb-gutter;
        transform: translateY(-50%);

        .swiper-button-next,
        .swiper-button-prev {
            position: absolute;
            top: 50%;
            height: 2em;
            width: 2em;
            padding: 0;
            transform: translate(-50%, -50%);
            // background-color: $color;

            .far {
                margin-left: 0;
                margin-right: 0;
            }

            &[aria-disabled="true"] {
                @extend .btn.is-disabled;
            }
        }

        .swiper-button-prev {
            left: 0;
        }

        .swiper-button-next {
            left: 100%;
        }
    }
}

&-thumb {
    width: $thumb-root-size;
    margin-left: $thumb-gutter;
    flex-shrink: 0;

    &-wrapper {
        padding-left: $thumb-arrow-space;
        width: $thumb-size;
        height: 100%;
        width: ($thumb-size + ($thumb-scroll * 2));
        overflow: auto;
    }

    &-item {
        height: ($thumb-size - $thumb-arrow-space);
        width: ($thumb-size - $thumb-arrow-space);
        margin-top: $thumb-gutter;
        transition: $transition--fast;
        opacity: 0.3;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        &:nth-child(1) {
            margin-top: 0;
        }

        &.is-active {
            opacity: 1;
        }
    }
}
}

.toolbox-notification {
    .popup-box {
        width: 600px;
        background-color: transparent;
    }

    &-icon {
        @extend %flex-center;
        height: 20em;
        width: 20em;
        margin: auto;
        border-radius: 50%;
        background-color: $color-accent-hex;

        svg {
            height: 75%;
            width: 75%;

            path {
                fill: $color-light;
            }
        }
    }

    &-actions {
        $local-gutter: 8px;
        margin-left: (0 - $local-gutter);
        margin-right: (0 - $local-gutter);
        margin-top: 2rem;

        .col-6 {
            padding: $local-gutter;
        }

        button {
            width: 100%;
            font-weight: 700;
        }
    }

    .toolbox-notification-dismiss {
        color: $color-accent-hex;
        background-color: $color-light;

        &:hover,
        &:focus {
            background-color: mix($color-light, $color-dark, 80);
        }
    }
}