.wysiwyg {
    text-align: left;
    text-align: start;

    // prettier-ignore
    h1, h2, h3, h4, h5, h6,
    p, blockquote, ul, ol {
        &:nth-child(1){
            margin-top: 0;
        }

        &:nth-last-child(1){
            margin-bottom: 0;
        }
    }

    table {
        table-layout: fixed;

        // the width will always in 100% no matter what
        max-width: 100% !important;
        width: 100%;

        td {
            // default value, keep in mind that every style here should be
            // customizable through CMS
            padding: 0.6em;
            vertical-align: top;
            vertical-align: text-top;
            border-style: solid;
            border-color: #dfdfdf;

            // it will add border eventhough they choose not to, it's the
            // requirement
            border-width: 1px !important;
        }
    }
}
