.voicemail {
    &-seekbar {
        position: relative;
        height: 2px;
        border-radius: 2px;
        background-color: mix($color-light, transparent, 30);

        span {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 0%;
            display: block;
            background-color: $color-light;

            &::after {
                @extend %absolute-center;
                content: "";
                left: 100%;
                height: 0.6rem;
                width: 0.6rem;
                border-radius: 50%;
                background-color: $color-light;
            }
        }
    }

    &-time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.4em;
        font-size: 0.86em;
    }

    &-play {
        display: block;
        margin: auto;
        margin-top: 1em;
    }

    &-player {
        display: none;
    }
}
