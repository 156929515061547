.contactGame {
    &__inner {
        padding: 50px 30px;
        background: rgba(0, 0, 0, .35);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px 0 60px 0;
        backdrop-filter: blur(5px);
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            border-width: 30px 0 0 30px;
            border-style: solid;
            border-color: rgba($color-accent-hex, 1) transparent transparent transparent;
        }
    }

    &__content {
        max-width: 500px;
        text-align: center;

        .btn {
            display: inline-block;
        }
    }

    &__copy {
        margin: 40px 0;
    }
}