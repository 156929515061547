.tutorial {
    $self: #{&};
    $gutter-x: 30px;
    @extend %absolute-overlay;
    z-index: $zindex-tutorial;

    // Animation
    transition: $transition--primary;
    opacity: 0;
    pointer-events: none;

    &-position {
        position: absolute;
        z-index: 2;
    }

    &-inner {
        width: 500px;
        padding: 15px;
        padding-top: 50px;
    }

    &-box {
        position: relative;
        padding: 24px;
        border-radius: 2px;
        box-shadow: 0 4px 20px 0 rgba($color-dark, 0.4);
        background-color: $color-light;
    }

    &-close {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-100%) translateY(-8px);
    }

    &-title {
        margin-top: 0;
    }

    &-svg {
        @extend %absolute-overlay;
        height: 100%;
        width: 100%;
        z-index: 0;
    }

    &.is-open {
        opacity: 1;
        pointer-events: inherit;
    }
}
