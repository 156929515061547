.sidepopup {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    padding: 30px 26px;
    z-index: 2;
    transition: $transition--fast;
    border-radius: 0 5px 0 0;
    color: $color-dark;
    background-color: $color-light;

    @include media-breakpoint-down(hd-small) {
        font-size: 0.86rem;
    }

    &.is-hidden {
        transform: translateX(-110%);
    }

    &-title {
        margin-bottom: 20px;
        word-break: break-all;

        &:after {
            content: "";
            display: block;
            height: 2px;
            width: 120px;
            margin-top: 20px;
            background-color: $color-accent-hex;
        }
    }

    &-content {
        margin-bottom: 26px;
    }

    &-btn {
        margin-top: 8px;
        display: block;
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        border: 1px solid rgba($color-accent, 1);

        &:nth-child(1) {
            margin-top: 0;
        }

        &.is-ready {
            color: $color-accent-hex;
            background-color: transparent;

            &:hover,
            &:focus {
                background-color: rgba($color-accent, .07);
            }
        }
    }
}