@keyframes smooth_scaling {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

@keyframes quick_shaking {
    from,
    to {
        transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
}

@keyframes shake_and_scaling {
    0% {
        transform: rotate(0deg) scale(1);
    }
    20% {
        transform: rotate(5deg) scale(0.9);
    }
    40% {
        transform: rotate(10deg) scale(1.1);
    }
    60% {
        transform: rotate(0deg) scale(0.9);
    }
    80% {
        transform: rotate(-10deg) scale(1.1);
    }
}

@keyframes smooth_move_vertical {
    0% {
        top: 0;
    }

    50% {
        top: -0.6em;
    }

    100% {
        top: 0;
    }
}

// @keyframes shake_horizontal {
//     0% {
//         left: 0;
//     }

//     45% {
//         left: -1em;
//     }

//     65% {
//         left: 1em;
//     }

//     100% {
//         left: 0;
//     }
// }
