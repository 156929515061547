.sources-feedback {
    & > div {
        display: flex;
        align-items: center;
        margin-top: 6px;
    }

    & > h4 {
        margin-top: 1em;
    }

    .image {
        position: relative;
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        margin-right: 1em;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}
