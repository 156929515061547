.popup {
    @extend %pseudo-overlay;
    position: fixed;
    z-index: $zindex-default-popup;
    pointer-events: none;
    transition: $transition--primary;
    opacity: 0;
    background-color: mix($color-dark, transparent, 60);

    $root: #{&};


    &__actions {
        display: flex;
        align-items: center;
        gap: 15px;
        padding-top: 10px;
        border-top: solid 1px #eeeeee;

        &.right-action {
            justify-content: flex-end;
        }
    }

    &-close {
        @extend %flex-center;
        position: absolute;
        top: 0;
        right: 0;
        height: 34px;
        width: 34px;
        padding: 0;
        transform: translateY(-100%) translateY(-6px);
        border-radius: 50%;

        .far,
        .far:nth-child(1),
        .far:nth-last-child(1) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &-wrapper {
        height: 100%;
        display: flex;
        align-items: center;

        #{$root}-box-wrapper {
            max-height: 100%;
            width: 100%;
            overflow: auto;
            margin: auto;
        }
    }

    &-inner {
        max-height: 100%;
        width: 100%;
        overflow: auto;
        margin: auto;
    }

    &-block {
        display: none;
        @extend %pseudo-overlay;
        // cursor: pointer;
    }

    &-box {
        position: relative;
        width: 920px;
        max-width: calc(100% - 40px);
        padding: 30px 60px;
        margin: 46px auto;
        font-size: 1.2rem;
        border-radius: 0;
        background: $color-light;

        &.small-box {
            max-width: 560px;
            padding: 30px;

            &.smallest {
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 20px;
                max-width: 360px;
            }
        }

        &.styled-box {
            border-radius: 10px;

            .popup-close {
                margin-top: 24px;
                right: 12px;
            }

            &.styled-red {
                background: $color-accent-hex;
                color: #ffffff;

                .popup-close {
                    border: solid 2px #ffffff;
                }

            }
        }

        @include media-breakpoint-down(hd) {
            font-size: 1rem;
        }
    }

    &-title {
        padding-top: 0.3em;
        text-align: center;
        color: $color-accent-hex;

        &::after {
            content: "";
            display: block;
            height: 2px;
            width: 260px;
            max-width: 60%;
            margin: auto;
            margin-top: 0.8em;
            background-color: $color-accent-hex;
        }
    }

    &-content {
        margin-top: 3em;
        line-height: 1.2;
    }

    &-actions {
        display: flex;
        margin-top: 24px;
    }

    &.small &-content {
        margin-top: 1.4em;
        margin-bottom: 1.4em;

        &:nth-child(1) {
            margin-top: 0;
        }
    }

    &.small &-box {
        display: table;
        width: auto;
    }

    &.is-open {
        pointer-events: inherit;
        opacity: 1;
    }

    &.new-member-popup &-box {
        padding: 0;
        max-width: 480px;
    }
}

.timer-alert-popup {
    z-index: $zindex-default-popup + 10;
}

.system-requirement-popup {
    &-next {
        margin-top: 14px;
    }
}