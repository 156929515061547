#page-faq {
    .faq-page {
        height: 100vh;
        overflow: auto;

        &-table {
            padding-top: $navbar-height;
            padding-bottom: $navbar-height;
            display: table;
            height: 100%;
            width: 100%;
            table-layout: fixed;
        }

        &-cell {
            display: table-cell;
            vertical-align: middle;
        }

        &-box {
            padding: 24px;
            padding-bottom: 40px;
            margin: auto;
            background-color: $color-light;
            @include make-col(10);
            @include media-breakpoint-down(md) {
                @include make-col(12);
            }
        }

        &-inner {
            margin: auto;
            @include make-col(10);
            @include media-breakpoint-down(sm) {
                @include make-col(11);
            }
        }
    }
}
