input[type="email"],
input[type="text"],
input[type="password"],
input[type="datetime"],
textarea,
select,
.input {
    width: 100%;
    // padding: 10px 14px;
    padding: 14px 14px;
    transition: $transition--fast;
    font-weight: 700;
    border: none;
    border-radius: 2px;
    box-shadow: 0px 0px 0px 0px transparent;
    color: $color-dark;
    background-color: $color-light;

    &::placeholder {
        font-weight: normal;
        color: $color-placeholder;
    }

    &:hover,
    &:focus {
        outline: none;
    }

    &:focus {
        box-shadow: 0px 0px 0px 1px $color-accent-hex;
    }

    &.light {
        border: 1px solid mix($color-dark, transparent, 20);

        &:hover,
        &:focus {
            box-shadow: none;
            border-color: $color-accent-hex;
        }
    }
}

.input.w-icon {
    display: flex;
    align-items: center;
    cursor: text;
    padding: 0;
    padding-left: 14px;
    padding-right: 14px;

    input {
        width: 100%;
        outline: none;
        border: none;

        &:hover,
        &:focus {
            box-shadow: none;
        }
    }

    &:focus,
    &:focus-within {
        box-shadow: 0px 0px 0px 1px $color-accent-hex;
    }
}

textarea {
    resize: none;
}

.select-wrapper {
    position: relative;
    width: fit-content;

    &::after {
        @extend .far;
        content: "\f078";
        position: absolute;
        top: 50%;
        right: 0.7em;
        font-size: 0.86em;
        transform: translateY(-50%);
        pointer-events: none;
        color: $color-accent-hex;
    }

    select {
        appearance: none;
    }
}