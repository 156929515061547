#subscribe-avatar {
    .subscribe {
        &-steps.row {
            $local-gutter: 30px;
            $local-gutter-medium: 20px;
            margin-left: (0 - $local-gutter);
            margin-right: (0 - $local-gutter);
            margin-top: 80px;

            @media screen and (max-width: 1300px) {
                margin-left: (0 - $local-gutter-medium);
                margin-right: (0 - $local-gutter-medium);
            }

            .col {
                width: 100%;
                padding: $local-gutter;

                @media screen and (max-width: 1300px) {
                    padding: $local-gutter-medium;
                }

                &:nth-child(odd) {
                    flex: 0 0 28%;
                    max-width: 28%;

                    @include media-breakpoint-down(lg) {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                }

                &:nth-child(even) {
                    flex: 0 0 22%;
                    max-width: 22%;

                    @include media-breakpoint-down(lg) {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                }
            }
        }
    }

    .step {
        $local-margin-top: 40px;

        small {
            display: block;
            margin-bottom: 10px;
            line-height: 1.2;
        }

        &-body {
            margin-top: $local-margin-top;

            button {
                display: block;
                margin-top: 14px;
                margin-right: 0;
                margin-left: auto;
            }
        }

        &-avatars {
            $local-size: 70px;
            $local-gutter: 6px;
            display: flex;
            flex-wrap: wrap;
            margin-left: (0 - $local-gutter);
            margin-right: (0 - $local-gutter);
            margin-top: ($local-margin-top - $local-gutter);

            // This should be defined before img hover state, because we still
            // using the hover effect even though user has selected a picture
            &.is-selected img {
                opacity: 0.5;
            }

            label {
                padding: $local-gutter;

                img {
                    cursor: pointer;
                    height: $local-size;
                    width: $local-size;
                    transition: $transition--fast;
                    border-radius: 50%;
                    box-shadow: 0 0 0 3px transparent;
                }

                input[type="radio"] {
                    position: absolute;
                    opacity: 0;
                    pointer-events: none;

                    &:hover+img,
                    &:focus+img {
                        opacity: 1;
                        box-shadow: 0 0 0 3px mix($color-light, transparent, 30);
                    }

                    &:checked+img {
                        opacity: 1;
                        box-shadow: 0 0 0 3px $color-light;
                    }
                }
            }
        }

        &-skills {
            $local-gutter: 6px;
            margin-top: $local-margin-top;
            display: flex;
            flex-wrap: wrap;
            margin-left: (0 - $local-gutter);
            margin-right: (0 - $local-gutter);

            &-item {
                padding: $local-gutter;

                input {
                    position: absolute;
                    opacity: 0;
                    pointer-events: none;

                    &:hover+label,
                    &:focus+label {
                        background-color: mix($color-light, transparent, 35);
                    }

                    &:checked+label {
                        color: $color-accent-hex;
                        background-color: $color-light;
                    }

                    &:checked:hover+label,
                    &:checked:focus+label {
                        background-color: mix($color-light, $color-dark, 90);
                    }
                }

                label {
                    cursor: pointer;
                    display: block;
                    padding: 6px 10px;
                    font-weight: 700;
                    transition: $transition--fast;
                    border-radius: 6px;
                    font-size: 0.93em;
                    border: 1px solid $color-light;
                }
            }
        }

        input[type="file"] {
            display: none;
        }

        &-avatar-upload {
            margin-top: 14px;

            .custom-avatar-file {
                display: flex;
                align-items: center;

                button {
                    margin-top: 0;
                    margin-left: 0;
                    margin-right: 10px;
                    flex-shrink: 0;
                }

                .filename {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}