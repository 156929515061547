.navbar {
    $logo-height: 80px;
    $padding-x: 20px;
    position: absolute;
    width: 100%;
    overflow: hidden;
    z-index: $zindex-navbar;
    top: 0;
    left: 0;
    padding: $padding-x;
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;

    &-logo {
        height: $logo-height;
        object-position: 0% 50%;
    }

    &-info {
        $phase-gutter: 0.3rem;
        $phase-size: 0.6rem;
        $phase-transition: $transition--primary;
        width: 120px;
        position: fixed;
        top: 20px;
        right: 90px;
        opacity: 0;
        z-index: $zindex-timer;
        transform: translateX(100px) translateY(#{$logo-height / 2}) translateY(-50%);
        transition: $transition--primary;

        &.is-active {
            opacity: 1;
            transform: translateX(0) translateY(#{$logo-height / 2}) translateY(-50%);
        }

        &-notifier {
            position: relative;
        }

        &-timer {
            padding: 4px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            border-radius: 5px;
            color: $color-light;
            background-color: $color-accent-hex;

            svg {
                height: 1.8em;
                width: 1.8em;
                margin-right: 8px;

                path {
                    fill: $color-light;
                }
            }

            &-divider {
                margin: 0 4px;
            }
        }

        &-phase {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: (0 - $phase-gutter);
            margin-right: (0 - $phase-gutter);

            &::after,
            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                z-index: 1;
                margin-left: $phase-gutter;
                margin-right: $phase-gutter;
                height: 1px;
                width: calc(100% - #{$phase-gutter * 2});
                transition: $phase-transition;
                background-color: $color-button-disabled;
            }

            &::after {
                width: 0;
                background-color: $color-accent-hex;
            }

            div {
                position: relative;
                z-index: 2;
                display: block;
                height: $phase-size;
                width: $phase-size;
                margin: $phase-gutter;
                transition: $phase-transition;
                border-radius: 50%;
                background-color: $color-button-disabled;
            }
        }

        &.is-notify &-notifier {
            animation-name: smooth_move_vertical;
            animation-duration: 1s;
            animation-iteration-count: 4;
            animation-fill-mode: forwards;
        }

        &.is-hurry-3 &-timer {
            animation-name: smooth_scaling;
            animation-duration: 1s;
            animation-iteration-count: 16;
            animation-fill-mode: forwards;
        }

        &.is-hurry-2 &-timer {
            animation-name: quick_shaking;
            animation-duration: 500ms;
            animation-iteration-count: 16;
            animation-fill-mode: forwards;
        }

        &.is-hurry &-timer {
            animation-name: shake_and_scaling;
            animation-duration: 500ms;
            animation-iteration-count: 16;
            animation-fill-mode: forwards;
        }

        &.is-phase-1 &-phase {
            &::after {
                width: 0;
            }

            div:nth-child(1) {
                background-color: $color-accent-hex;
            }
        }

        &.is-phase-2 &-phase {
            &::after {
                width: calc(33% - #{$phase-gutter * 2} + #{$phase-size});
                // width: 50%;
            }

            div:nth-child(1),
            div:nth-child(2) {
                background-color: $color-accent-hex;
            }
        }

        &.is-phase-3 &-phase {
            &::after {
                width: calc(64% - #{$phase-gutter * 2} + #{$phase-size});
            }

            div:nth-child(1),
            div:nth-child(2),
            div:nth-child(3) {
                background-color: $color-accent-hex;
            }
        }

        &.is-phase-4 &-phase {
            &::after {
                width: calc(100% - #{$phase-gutter * 2});
            }

            div:nth-child(1),
            div:nth-child(2),
            div:nth-child(3),
            div:nth-child(4) {
                background-color: $color-accent-hex;
            }
        }
    }
}

#finish-game {
    position: absolute;
    right: 100%;
    white-space: nowrap;
    top: 0;
    margin-right: 20px;

    .btn {
        line-height: 105%;
        padding: 10px 20px;
        border-radius: 5px;
        animation: finishPulse infinite 1.5s ease-in-out;

        @keyframes finishPulse {
            0% {
                box-shadow: 0 0 0 0 rgba($color-accent-hex, 0);
            }

            33% {
                box-shadow: 0 0 0 5px rgba($color-accent-hex, 0.6);
            }

            66% {
                box-shadow: 0 0 0 5px rgba($color-accent-hex, 0);
            }

            100% {
                box-shadow: 0 0 0 0 rgba($color-accent-hex, 0);
            }
        }
    }
}