// ----------------------------------------------------------------------------
// This file contains all the fonts that used in the CSS
// ----------------------------------------------------------------------------

@font-face {
    font-family: "Quicksand";
    src: url("../../fonts/Quicksand/Quicksand-Light.ttf");
    font-weight: 300;
}

@font-face {
    font-family: "Quicksand";
    src: url("../../fonts/Quicksand/Quicksand-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Quicksand";
    src: url("../../fonts/Quicksand/Quicksand-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Quicksand";
    src: url("../../fonts/Quicksand/Quicksand-SemiBold.ttf");
    font-weight: 600;
}

@font-face {
    font-family: "Quicksand";
    src: url("../../fonts/Quicksand/Quicksand-Bold.ttf");
    font-weight: 700;
}
