#subscribe-role {
    position: relative;
    background-position: center;
    background-size: cover;
    // background-image: url("../../images/login.png");

    &::before {
        @extend %pseudo-overlay;
        opacity: 0.5;
        background-color: $color-dark;
    }

    .subscribe123 {
        height: 100vh;
        display: table;
        color: $color-light;

        &-wrapper {
            display: table-cell;
            vertical-align: middle;
            padding-top: $navbar-height;
        }

        &-suptitle {
            font-weight: bold;
            text-transform: uppercase;
        }

        &-title {
            margin-top: 0;
            margin-bottom: 0.2em;
            @include media-breakpoint-up(lg) {
                margin-left: -0.8em;
            }
        }

        &-description {
            @include media-breakpoint-up(lg) {
                width: 70%;
            }
        }

        &-roles {
            margin-top: 80px;
            display: flex;
        }

        &-role {
            margin-left: 130px;

            &:nth-child(1) {
                margin-left: 0;
            }

            &-icon {
                $size: 150px;
                height: $size;
                width: $size;
                margin: auto;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }

            button {
                display: block;
                margin: auto;
                margin-top: 40px;
            }

            &.role-leader .subscribe123-role-icon {
                background-image: url("../../images/role-icon-leader.svg");
            }

            &.role-participant .subscribe123-role-icon {
                background-image: url("../../images/role-icon-participant.svg");
            }
        }
    }
}
