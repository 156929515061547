.videobox {
    position: relative;
    overflow: hidden;
    transition: $transition--fast;
    background-color: $color-dark;

    // Video contain without aspect ratio
    // height: 100%;

    // Video cover 16:9 ratio
    height: 0;
    width: 100%;
    padding-bottom: 56.25%;

    // height: 100%;
    // width: 0;
    // padding-left: 177.77%;

    &::after {
        @extend %pseudo-loading;
        font-size: 3rem;
        opacity: 0;
        color: $color-light;
    }

    &.is-loading::after {
        display: block;
    }

    &.is-hidden {
        opacity: 0;
        pointer-events: none;
    }

    video {
        height: 100%;
        width: 100%;
        opacity: 0.6;
        transition: $transition--fast;

        // Video cover 16:9 ratio
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        // Video cover size, IE friendly
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // min-height: 100%;
        // min-width: 100%;
        // height: auto;
        // width: auto;
    }

    &-seekbar {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 8px;
        transform: translateY(110%);
        transition: $transition--fast;
        background-color: mix($color-light, transparent, 20);

        span {
            display: block;
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            transition: 500ms linear;
            background-color: $color-accent-hex;
        }
    }

    &-play {
        @extend %absolute-center;
        padding: 0.2em;
        font-size: 5rem;
        background-color: transparent;

        &:hover,
        &:focus {
            background-color: mix($color-light, transparent, 10);
        }

        &>* {
            display: block;
            margin: auto;
        }

        .far {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        span {
            font-size: 0.3em;
            margin-top: 0.4em;
        }
    }

    &.is-playing {
        &::after {
            opacity: 1;
        }

        video {
            opacity: 1;
        }

        .videobox-play {
            display: none;
        }

        .videobox-seekbar {
            transform: translateY(0);
        }
    }
}

.popup.videobox-popup {
    background-color: mix($color-dark, transparent, 70);

    .popup-box {
        width: 78vw;
        max-width: 100%;
        padding: 0;
        border-radius: 0;
        background-color: transparent;

        // Video contain without aspect ratio
        // height: 80vh;

        // Video cover 16:9 ratio
        height: auto;

        // Device with crazy aspect ratio, wider screen
        @media (min-aspect-ratio: 18 / 9) {
            width: 68vw;
        }

        // Device with crazier aspect ratio, wider than wider screen
        @media (min-aspect-ratio: 20 / 9) {
            width: 58vw;
        }

        // Device with ultra crazy aspect ratio, wider wider than wider screen
        // more than this it's user's fault, why buy screen with crazy ratio :D
        @media (min-aspect-ratio: 22 / 9) {
            width: 48vw;
        }
    }
}