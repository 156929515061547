.passwordbox {
    $self: #{&};
    position: relative;
    width: 350px;

    $key-gutter: 3px;
    $key-size: 34px;
    $key-additional-space: 0px;

    &::after {
        @extend %pseudo-loading;
        top: 22%;
        color: $color-light;
    }

    &-objectruler {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 200%;
        background-image: url("../../images/passwordbox.png");
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
    }

    &-input {
        position: absolute;
        top: 13%;
        left: 50%;
        width: 47%;
        height: 19%;
        outline: none;
        transform: translateX(-52%);
        text-align: center;
        font-size: 1.4em;
        border-width: 0;
        color: $color-light;
        background-color: transparent;
        // background-color: $color-light;

        &:hover,
        &:focus {
            outline: none;
        }

        &::placeholder {
            font-size: 0.8em;
        }
    }

    &-error {
        position: absolute;
        top: (13% + 19%);
        left: 50%;
        width: 45%;
        display: none;
        transform: translate(-50%, -150%);
        text-align: center;
        text-shadow: 1px 1px #f99189;
        color: $color-danger;
    }

    &-interactive {
        position: absolute;
        width: 45%;
        height: 21%;
        top: 33.6%;
        left: 50%;
        transform: translateX(-52%);
    }

    &-row {
        display: flex;
        margin: (0 - $key-gutter);
    }

    // Overlay circle above key image
    &-key {
        position: relative;
        height: ($key-size + $key-additional-space);
        width: ($key-size + $key-additional-space);
        transition: $transition--fast;
        flex-shrink: 0;
        cursor: pointer;
        margin: $key-gutter;
        border-radius: 50%;
        background-color: transparent;

        &:hover {
            background-color: mix($color-light, transparent, 20);
        }

        &:focus {
            background-color: transparent;
        }

        &:active {
            background-color: mix($color-dark, transparent, 60);
        }

        // Some little adjustment to be pixel perfect ( blame the image -_- )
        // --------------------------------------------------------------------
        &[data-value="2"] {
            transform: translate(2px, 0);
        }
        &[data-action="clear"] {
            transform: translate(2px, 0);
        }
        &[data-value="4"] {
            transform: translate(2px, -1px);
        }
        &[data-value="5"] {
            transform: translate(2px, -1px);
        }
        &[data-value="7"] {
            transform: translate(2px, -2px);
        }
        &[data-value="8"] {
            transform: translate(1px, -2px);
        }
        &[data-action="left"] {
            transform: translate(3px, -2px);
        }
        &[data-value="0"] {
            transform: translate(2px, -2px);
        }
        &[data-action="right"] {
            transform: translate(0, -2px);
        }
        &[data-action="submit"] {
            transform: translate(-2px, -2px);
        }
    }

    &-row.is-scanner {
        margin-top: 40%;

        #{$self}-key {
            height: 116px;
            width: 106px;
            margin: auto;
            border-radius: 2px 2px 40% 40%;
        }
    }

    &.is-loading {
        &::after {
            display: block;
        }

        #{$self}-input {
            background-color: mix($color-light, transparent, 20);
        }
    }
}

.main-office-dataroom.is-error .passwordbox-error {
    display: block;
}
