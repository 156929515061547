.btn-group {
    $button-gutter: 6px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: (0 - $button-gutter);
    margin-left: (0 - $button-gutter);

    .btn {
        display: block;
        margin: 0 $button-gutter;
    }
}
