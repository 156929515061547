.playroom-header {
    position: fixed;
    width: 100vw;
    top: 30px;
    z-index: $zindex-playroom-header;
    text-align: center;
    color: $color-light;
    pointer-events: none;

    // New Style
    top: 0;
    padding: 30px 0;
    $bg-1: mix($color-dark, transparent, 36);
    $bg-2: transparent;
    background-image: linear-gradient($bg-1, $bg-2);

    &-title {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1.8em;
        line-height: 1;
    }

    &-content {
        margin-top: -0.4em;
        font-weight: 700;
        font-size: 0.92em;

        p {
            font-weight: inherit;
        }
    }
}
