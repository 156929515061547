.gameSessions {
    display: flex;
    gap: 15px;
    width: 100%;
    align-items: center;
    padding: 15px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    backdrop-filter: blur(5px);

    &__thumbnail {
        height: 100%;
        background-size: cover;
        background-position: center;
        width: 80px;
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        animation: gameSessionpulse infinite 1.5s ease-in-out;

        @keyframes gameSessionpulse {
            0% {
                box-shadow: 0 0 0 0 rgba($color-accent-hex, 0);
            }

            33% {
                box-shadow: 0 0 0 5px rgba($color-accent-hex, 0.6);
            }

            66% {
                box-shadow: 0 0 0 5px rgba($color-accent-hex, 0);
            }

            100% {
                box-shadow: 0 0 0 0 rgba($color-accent-hex, 0);
            }
        }

        span.play {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color-accent, .6);

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-style: solid;
                border-width: 14px 0 14px 20px;
                border-color: transparent transparent transparent #ffffff;
            }
        }
    }

    &__detail {
        display: flex;
        text-align: left;
        flex-direction: column;
        gap: 0;

        h5,
        p {
            margin: 0;
        }

        h5 {
            font-size: 1.2em;
        }
    }

    &__actions {
        display: flex;
        gap: 10px;
        padding-left: 15px;

        .btn {
            margin: 0;
            padding: 10px 15px;

            .p-landing__actions & {
                margin: 0;

                @media screen and (min-width: 768px) {
                    padding: 10px 15px;
                }
            }
        }
    }

    &__wrap {
        #restartGame {
            display: none;
        }

        #startGame {
            display: flex;
            animation: gameSessionfade .3s ease-in-out forwards;
        }

        &.is-session-exist {
            #restartGame {
                display: flex;
                animation: gameSessionfade .3s ease-in-out forwards;
            }

            #startGame {
                display: none;
            }
        }

        @keyframes gameSessionfade {
            from {
                opacity: 0;
                visibility: hidden;
            }

            to {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}