.subscribe {
    position: relative;
    min-height: 100vh;
    padding-top: 30px;
    padding-bottom: 60px;
    color: $color-light;
    background-position: center;
    background-size: cover;
    // background-image: url("../../images/login.png");

    &::before {
        @extend %pseudo-overlay;
        opacity: 0.8;
        background-color: $color-dark;
    }

    &-wrapper {
        padding-top: $navbar-height;
        overflow: hidden;
    }

    &-container-wide {
        width: 1500px;
        max-width: 90%;
        // @include media-breakpoint-down(xl) {
        //     max-width: 90%;
        //     // margin-right: 40px;
        //     // margin-left: 40px;
        // }
    }

    &-suptitle {
        font-weight: bold;
        text-transform: uppercase;
    }

    &-title {
        margin-top: 0;
    }

    &-description {
        @include media-breakpoint-up(lg) {
            width: 70%;
        }
    }

    &-step {
        .step-body {
            margin-top: 40px;

            button {
                display: block;
                margin-top: 14px;
                margin-right: 0;
                margin-left: auto;
            }
        }

        &.proceed {
            .step-body button {
                min-width: 100%;
                margin-top: 0 !important;

                @include media-breakpoint-down(lg) {
                    min-width: 0;
                }
            }

            .step-proceed-info {
                display: none;
                margin-top: 6px;
                font-size: 0.86em;
                text-align: right;

                &.is-active {
                    display: block;
                }
            }
        }
    }

    & &-steps-submit {
        padding: 8px;
        font-size: 0.875em;
        // @include media-breakpoint-down(xl) {
        //     font-size: 0.875em;
        // }
    }
}
