.playerinfo {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 3;
    text-align: right;
    color: $color-light;

    &-title {
    }

    &-members {
        margin-top: 10px;

        img {
            $avatar-size: 60px;
            height: $avatar-size;
            width: $avatar-size;
            object-fit: cover;
            display: block;
            margin: auto;
            margin-right: 0;

            // -20% of $avatar-size
            margin-top: (0 - ($avatar-size * 20 / 100));

            border-radius: 50%;

            &:nth-child(1) {
                margin-top: 0;
            }
        }
    }
}
