#leaderBoard {
    min-height: 100vh;
    padding-top: $navbar-height;
    color: $color-light;
    background-position: center;
    background-size: cover;
    height: 100vh;
    overflow: hidden;

    &::before {
        @extend %pseudo-overlay;
        opacity: 0.8;
        background-color: $color-dark;
    }
}